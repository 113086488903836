<div class="tenant-selection-container">
  <p-listbox [options]="tenants$ | async" [(ngModel)]="selectedTenantInternal">
    <ng-template let-tenant pTemplate="item">
      <div class="selection-list-entries" (click)="selectTenant(tenant)">
        <div>{{ tenant.name }}</div>
        <i class="pi pi-chevron-right select-icon"></i>
      </div>
    </ng-template>
  </p-listbox>
</div>
