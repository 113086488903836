import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable, take } from 'rxjs';
import { TableModule } from 'primeng/table';
import { DataService } from '@shared/services/data/data.service';
import { AzureSubscriptionDto } from 'app/features/admin/services/dtos/azure-subscriptions.dto';
import { AzureResourceGroupDto } from 'app/features/admin/services/dtos/azure-resource-groups.dto';
import { Tenant } from 'app/features/admin/models/tenant';
import { AzureResourceDto } from 'app/features/admin/services/dtos/azure-resources.dto';
import { ConfirmSelectionModel } from './model/confirm-selection.model';

@Component({
  selector: 'app-confirm-selection',
  templateUrl: './confirm-selection.component.html',
  styleUrl: './confirm-selection.component.scss',
  standalone: true,
  imports: [TableModule],
})
export class ConfirmSelectionComponent implements OnInit {
  @Input({ required: true }) selectedTenant$: Observable<Tenant>;
  @Input({ required: true }) selectedSubscription$: Observable<AzureSubscriptionDto>;
  @Input({ required: true }) selectedResourceGroup$: Observable<AzureResourceGroupDto>;
  @Input({ required: true }) selectedResource$: Observable<AzureResourceDto>;

  tenant: Tenant;
  subscription: AzureSubscriptionDto;
  resourceGroup: AzureResourceGroupDto;
  resource: AzureResourceDto;

  currentSelectionTexts: ConfirmSelectionModel[];

  constructor(public dataService: DataService) {}

  ngOnInit(): void {
    forkJoin([
      this.selectedTenant$.pipe(take(1)),
      this.selectedSubscription$.pipe(take(1)),
      this.selectedResourceGroup$.pipe(take(1)),
      this.selectedResource$.pipe(take(1)),
    ]).subscribe(([tenant, subscription, resourceGroup, resource]) => {
      this.currentSelectionTexts = [
        { infoText: this.dataService.getTexts().AddResourceConfirmationTenant, itemName: tenant.name },
        {
          infoText: this.dataService.getTexts().AddResourceConfirmationSubscription,
          itemName: subscription.displayName,
        },
        { infoText: this.dataService.getTexts().AddResourceConfirmationResourceGroup, itemName: resourceGroup.name },
        { infoText: this.dataService.getTexts().AddResourceConfirmationResource, itemName: resource.name },
      ];
    });
  }
}
