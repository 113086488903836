export const DIALOG_CONFIG = {
  DELETE: {
    height: 'fit-content',
    width: 'fit-content',
  },
  INVITE: {
    height: 'fit-content',
    width: '40vw',
  },
  ADD_RESOURCE: {
    height: 'fit-content',
    width: 'fit-content',
  },
  ADD_API_KEY: {
    height: 'fit-content',
    width: 'fit-content',
  },
  ORGANIZATION_FILTER: {
    height: 'fit-content',
    width: 'fit-content',
  },
  PASSWORD_RESET: {
    height: 'fit-content',
    width: 'fit-content',
  },
  DEPARTMENT_CREATE: {
    height: 'fit-content',
    width: 'min(100%, 800px)',
  },
  DEPARTMENT_LOG_FILTER: {
    height: 'fit-content',
    width: '40vw',
  },
  TEMPLATESPEC_DELETE: {
    height: 'fit-content',
    width: '40vw',
  },
};
