<div class="select-resource-container">
  <p-listbox [options]="azureResources$ | async" [(ngModel)]="selectedResourceInternal">
    <ng-template let-resource pTemplate="item">
      <div class="selection-list-entries" (click)="selectResource(resource)">
        <div>
          <p>
            {{ resource.name }}
          </p>
          <p style="font-size: smaller">
            {{ resource.type }}
          </p>
        </div>
        <i class="pi pi-chevron-right select-icon"></i>
      </div>
    </ng-template>
  </p-listbox>
</div>
