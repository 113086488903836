import { CreateCronJobDTO } from '../../models/createCronJobDTO';
import { GetTenantDTO } from '../../models/getTenantDTO';
import { Job } from '../../models/job';
import { Department } from '../../models/department';
import { ResourceJobSelectionDTO } from '../../models/resourceJobSelectionDTO';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UserDTO } from '@shared/models/userDTO';
import { GetNameOfEmail } from '@shared/util/emailToName';
import { catchError, Observable, retry, shareReplay, throwError } from 'rxjs';
import { DepartmentDTO } from '@shared/models/departmentDTO';
import { VmPowerStatusDTO } from '@shared/models/vmPowerStatusDTO';
import { CreateUserDTO } from '../../models/createUserDTO';
import { DepartmentFilter } from '../../models/departmentFilter';
import { Log } from '../../models/log';
import { OrganizationFilter } from '../../models/organizationFilter';
import { ResourceDto } from 'app/core/api/models/resourceDTO.model';
import { Tenant } from '../../models/tenant';
import { UpdateCronJobDTO } from '../../models/updateCronJobDTO';
import { SearchUserRequestDTO } from '@shared/models/searchUserRequestDTO';
import { ApplicationSecret } from '../../models/applicationSecret';
import { CreatedApplication } from '../../models/createdApplication';
import { ResetPasswordDTO } from '@shared/models/resetPasswordRequestDTO';
import { TenantDTO } from '../../models/tenantDTO';
import { OrganizationDto } from '../../models/organizationDto';
import { Templatespecs } from '../../models/templatespecs';
import { CreateTenantDTO } from '../../models/createTenanatDTO';
import { ARMTemplate } from '../../models/armTemplate';
import { CostQueryRequestDto, CostQueryResponseDto } from '../../../../core/api/models/costDashboardQueryDTO.model';
import { DeleteTemplateSpecDTO } from '../../models/deleteTemplateSpecDto';
import { TemplateSpec } from '../../pages/admin-settings/template-spec/models/templateSpec';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.baseUrl;

  private microsoftGraphUserEndpoint = environment.microsoftGraphUserEndpoint;

  private authenticationMethodResetPath = environment.authenticationMethodResetPath;

  private departmentController = 'management/departments';

  private resourceController = 'management/resources';

  private tenantController = 'management/tenants';

  private jobController = 'management/cronjobs';

  private userController = 'management/users';

  private suggestionController = 'management/users/search';

  private organizationController = 'management/organizations';

  private templatespecsController = 'management/templatespecs';

  private virtualmachineList$: Observable<ResourceDto[]>;

  private tenantList$: Observable<CreateTenantDTO[]>;

  private adminTenantsList$: Observable<Tenant[]>;

  constructor(private http: HttpClient) {}

  createResource(
    name: string,
    type: string,
    resourceGroup: string,
    tenant: string,
    department: string,
    subscriptionId: string,
  ): Observable<ResourceDto> {
    var resourceType = type;
    if (resourceType == 'Microsoft.Compute/virtualMachines') resourceType = 'Virtual Machine';
    const body = {
      name: name,
      type: resourceType,
      resourceGroup: resourceGroup,
      tenant: tenant,
      department: department,
      subscriptionId: subscriptionId,
    };
    return this.http.post<ResourceDto>(this.baseUrl + this.resourceController, body);
  }

  getAdminTenants(): Observable<Tenant[]> {
    if (!this.adminTenantsList$) {
      this.adminTenantsList$ = this.http
        .get<Tenant[]>(this.baseUrl + this.tenantController + '/admin')
        .pipe(retry(3), catchError(this.formatErrors))
        .pipe(shareReplay(1));
    }
    return this.adminTenantsList$;
  }

  getDepartments(): Observable<Department[]> {
    return this.http.get<DepartmentDTO[]>(this.baseUrl + this.departmentController + '/admin');
  }

  getTemplatespecs(): Observable<Templatespecs[]> {
    return this.http.get<Templatespecs[]>(this.baseUrl + this.templatespecsController + '/all');
  }

  getTemplatespecResources(templateSpecId: string, versions: string): Observable<ARMTemplate> {
    return this.http.get<ARMTemplate>(
      this.baseUrl + this.templatespecsController + '/' + templateSpecId + '/version/' + versions,
    );
  }

  getTemplatespecsVersion(templateSpecId: string): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + this.templatespecsController + '/' + templateSpecId + '/versions');
  }

  getTemplatespecsWithVersion(): Observable<TemplateSpec[]> {
    return this.http.get<TemplateSpec[]>(this.baseUrl + this.templatespecsController + '/withversions');
  }

  postAddTemplateSpecSingle(payload: any): Observable<Templatespecs> {
    return this.http.post<Templatespecs>(
      this.baseUrl + this.templatespecsController + '/addsingletemplatespecs',
      payload,
    );
  }

  getVirtualMaschiens(): Observable<ResourceDto[]> {
    if (!this.virtualmachineList$) {
      this.virtualmachineList$ = this.http
        .get<ResourceDto[]>(this.baseUrl + this.resourceController + '/admin')
        .pipe(catchError(this.formatErrors));
    }
    return this.virtualmachineList$;
  }

  powerOnVirtualMachine(vmId: string): Observable<Object> {
    return this.http
      .put(this.baseUrl + this.resourceController + vmId + '/vms/start', null)
      .pipe(catchError(this.formatErrors));
  }

  powerOffVirtualMachine(vmId: string): Observable<Object> {
    return this.http
      .put(this.baseUrl + this.resourceController + vmId + '/vms/stop', null)
      .pipe(catchError(this.formatErrors));
  }

  getJobsByOrganizationId(organizationId: string): Observable<Job[]> {
    return this.http.get<Job[]>(this.baseUrl + this.jobController + '/organizations/' + organizationId);
  }

  addUserToDepartment(departmentId: string, user: string, token: string): Observable<UserDTO> {
    var name = GetNameOfEmail.getNameOfEmail(user);

    var userDTO = {
      email: user,
      name: name,
      departmentId: departmentId,
    } as CreateUserDTO;

    let body = {
      Token: token,
      CreateUserRequestDTO: userDTO,
    };

    return this.http.post<UserDTO>(this.baseUrl + this.userController, body);
  }

  deleteJob(jobId: string): Observable<Job> {
    return this.http.delete<Job>(this.baseUrl + this.jobController + '/' + jobId);
  }

  getJobResources(organizationId: string, resourceType: string): Observable<ResourceJobSelectionDTO[]> {
    return this.http.get<ResourceJobSelectionDTO[]>(
      this.baseUrl +
        this.resourceController +
        '/organization/' +
        organizationId +
        '/resourceType/' +
        resourceType.replace(/ /g, '').toLowerCase(),
    );
  }

  createJob(job: CreateCronJobDTO): Observable<Job> {
    return this.http.post<Job>(this.baseUrl + this.jobController, job);
  }

  updateCronJob(jobToUpdate: UpdateCronJobDTO): Observable<Job> {
    return this.http.put<Job>(this.baseUrl + this.jobController, jobToUpdate);
  }

  private formatErrors(error: any): Observable<never> {
    return throwError(() => new Error(error.error));
  }

  removeResourceFromDepartment(departmentId: string, resourceId: string): Observable<any> {
    return this.http.delete<any>(
      this.baseUrl + this.resourceController + '/' + resourceId + '/departments/' + departmentId,
    );
  }

  deleteDepartment(departmentId: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + this.departmentController + '/' + departmentId);
  }

  deleteTemplatespec(deleteTemplateSpecDto: DeleteTemplateSpecDTO[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: deleteTemplateSpecDto,
    };
    return this.http.delete<void>(this.baseUrl + this.templatespecsController, options);
  }

  deleteTemplatespecDatabase(deleteTemplateSpecDto: DeleteTemplateSpecDTO[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: deleteTemplateSpecDto,
    };
    return this.http.delete<void>(this.baseUrl + this.templatespecsController + '/database', options);
  }

  renameDepartment(departmentId: string, name: string): Observable<DepartmentDTO> {
    const headers = { 'Content-Type': 'application/json' }; // Set the Content-Type header
    const requestBody = { name: name }; // Create a request body object with the new name

    return this.http.put<DepartmentDTO>(
      this.baseUrl + this.departmentController + '/' + departmentId + '/name',
      requestBody,
      { headers: headers },
    );
  }

  deleteTenant(tenantId: string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + this.tenantController + '/' + tenantId);
  }

  getLogsByDepartmentId(departmentId: string): Observable<Log[]> {
    return this.http.get<Log[]>(this.baseUrl + this.departmentController + '/' + departmentId + '/logs');
  }

  getNameOfUser(departmentId: string, userId: string): Observable<string> {
    return this.http.get<string>(this.baseUrl + 'management/logs/departments/' + departmentId + '/user/' + userId);
  }

  getVmPowerStatus(resourceIds: string[]): Observable<VmPowerStatusDTO[]> {
    return this.http.put<VmPowerStatusDTO[]>(this.baseUrl + this.resourceController + '/vms/powerstatus', resourceIds);
  }

  getVmPowerStatusUpdate(resourceIds: string[]): Observable<VmPowerStatusDTO[]> {
    return this.http.put<VmPowerStatusDTO[]>(
      this.baseUrl + this.resourceController + '/vms/powerstatus/update',
      resourceIds,
    );
  }

  startVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentController + '/' + departmentId + '/resources/' + resourceId + '/vms/start',
      null,
    );
  }

  restartVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentController + '/' + departmentId + '/resources/' + resourceId + '/vms/restart',
      null,
    );
  }

  stopVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentController + '/' + departmentId + '/resources/' + resourceId + '/vms/stop',
      null,
    );
  }

  getLogsOfDepartmentWithFilter(filter: DepartmentFilter): Observable<Log[]> {
    return this.http.put<Log[]>(this.baseUrl + 'management/departments/logs/filter', filter);
  }

  getLogsByOrganizationId(organizationId: string): Observable<Log[]> {
    return this.http.get<Log[]>(this.baseUrl + 'management/organizations/' + organizationId + '/logs');
  }

  getLogsOfOrganizationWithFilter(filter: OrganizationFilter): Observable<Log[]> {
    return this.http.put<Log[]>(this.baseUrl + 'management/organizations/logs/filter', filter);
  }

  getTenant(tenantId: string): Observable<GetTenantDTO> {
    return this.http.get<GetTenantDTO>(this.baseUrl + this.tenantController + '/' + tenantId);
  }

  changeDepartmentDescription(departmentId: string, description: string): Observable<DepartmentDTO> {
    const headers = { 'Content-Type': 'application/json' }; // Set the Content-Type header
    const requestBody = { description: description }; // Create a request body object with the description

    return this.http.put<DepartmentDTO>(
      `${this.baseUrl}${this.departmentController}/${departmentId}/description`,
      requestBody,
      { headers: headers },
    );
  }

  confirmOnboarding(onboardingId: string, customerEmail: string): Observable<boolean> {
    let body = {
      OnboardingId: onboardingId,
      CustomerEmail: customerEmail,
    };
    return this.http.put<boolean>(this.baseUrl + 'onboarding/confirm', body);
  }

  finishOnboarding(body: any): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'onboarding/finish', body);
  }

  createApplication(redirectUri: string): Observable<CreatedApplication> {
    return this.http.post<CreatedApplication>('https://graph.microsoft.com/v1.0/applications', {
      displayName: 'CloudControl-Automated-Service-Principal',
      requiredResourceAccess: [
        {
          resourceAppId: '00000003-0000-0000-c000-000000000000',
          resourceAccess: [
            {
              id: 'e1fe6dd8-ba31-4d61-89e7-88639da4683d',
              type: 'Scope',
            },
            {
              id: 'a154be20-db9c-4678-8ab7-66f6cc099a59',
              type: 'Scope',
            },
            {
              id: '06da0dbc-49e2-44d2-8312-53f166ab848a',
              type: 'Scope',
            },
            {
              id: 'df021288-bdef-4463-88db-98f22de89214',
              type: 'Role',
            },
            {
              id: '7ab1d382-f21e-4acd-a863-ba3e13f7da61',
              type: 'Role',
            },
            {
              id: '06b708a9-e830-4db3-a914-8e69da51d44f',
              type: 'Role',
            },
            {
              id: '19dbc75e-c2e2-444c-a770-ec69d8559fc7', // ID für Directory.ReadWrite.All
              type: 'Role', // Typ 'Role' für Anwendungsberechtigungen
            },
            {
              id: '9e3f62cf-ca93-4989-b6ce-bf83c28f9fe8',
              type: 'Role',
            },
            {
              id: 'd01b97e9-cbc0-49fe-810a-750afd5527a3',
              type: 'Scope',
            },
          ],
        },
      ],
      //TODO: Set reply URLs
      spa: {
        redirectUris: [redirectUri],
      },
    });
  }

  getApplication(appId: string): Observable<any> {
    return this.http.get<any>(
      'https://graph.microsoft.com/v1.0/applications/' + appId + '?$select=id,appId,displayName,requiredResourceAccess',
    );
  }

  addSecretToServicePrincipal(appId: string): Observable<ApplicationSecret> {
    return this.http.post<ApplicationSecret>(
      'https://graph.microsoft.com/v1.0/applications/' + appId + '/addPassword',
      {
        passwordCredential: {
          displayName: 'CloudControl-ServicePrincipal-Secret',
        },
      },
    );
  }

  deleteApplication(appId: string): Observable<any> {
    return this.http.delete<any>('https://graph.microsoft.com/v1.0/applications/' + appId);
  }

  deleteServicePrincipal(spId: string): Observable<any> {
    return this.http.delete<any>('https://graph.microsoft.com/v1.0/servicePrincipals/' + spId);
  }

  getUserSuggestions(
    searchString: string,
    tenantsToInclude: Array<TenantDTO> = new Array<TenantDTO>(),
  ): Observable<Array<UserDTO>> {
    const searchRequest: SearchUserRequestDTO = { searchTerm: searchString, tenantsToInclude: tenantsToInclude };
    return this.http.post<Array<UserDTO>>(this.baseUrl + this.suggestionController, searchRequest);
  }

  resetPasswordForUser(userId: string, accessToken: string, newPassword: string | null): Observable<string> {
    if (newPassword === null) {
      return this.http.post<string>(
        this.microsoftGraphUserEndpoint + userId + this.authenticationMethodResetPath,
        null,
      );
    }

    let body: ResetPasswordDTO = {
      token: accessToken,
      newPassword: newPassword,
    };
    return this.http.post<string>(this.microsoftGraphUserEndpoint + userId + this.authenticationMethodResetPath, body);
  }

  getOrganizationInformation(organizationId: string): Observable<OrganizationDto> {
    return this.http.get<OrganizationDto>(this.baseUrl + this.organizationController + '/' + organizationId);
  }

  updateOrganization(organizationId: string, organization: OrganizationDto): Observable<OrganizationDto> {
    return this.http.put<OrganizationDto>(
      this.baseUrl + this.organizationController + '/' + organizationId,
      organization,
    );
  }

  updateUser(user: UserDTO): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.baseUrl + this.userController, user);
  }

  /**
   * WILL BE REPLACED BY THE NEW API IN THE CORE FOLDER
   */
  queryPrivateGptCost(costQueryRequestDto: CostQueryRequestDto): Observable<CostQueryResponseDto> {
    return this.http.post<CostQueryResponseDto>(
      this.baseUrl + 'management/organizations/aimodels/cost',
      costQueryRequestDto,
    );
  }

  getApiHealthStatus(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'health');
  }

  updateDepartment(department: DepartmentDTO): Observable<DepartmentDTO> {
    return this.http.put<DepartmentDTO>(this.baseUrl + this.departmentController + '/' + department.id, department);
  }
}
