// Available themes from the file \node_modules\primeng\themes\aura\aura.d.ts

export const AVAILABLE_THEMES = {
  AMBER: 'amber',
  BLUE: 'blue',
  CYAN: 'cyan',
  EMERALD: 'emerald',
  FUCHSIA: 'fuchsia',
  GRAY: 'gray',
  GREEN: 'green',
  INDIGO: 'indigo',
  LIME: 'lime',
  NEUTRAL: 'neutral',
  ORANGE: 'orange',
  PINK: 'pink',
  PURPLE: 'purple',
  RED: 'red',
  ROSE: 'rose',
  SKY: 'sky',
  SLATE: 'slate',
  STONE: 'stone',
  TEAL: 'teal',
  VIOLET: 'violet',
  YELLOW: 'yellow',
  ZINC: 'zinc',
} as const;
