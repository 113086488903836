<div class="resource-group-selection-container">
  <p-listbox [options]="azureResourceGroups$ | async" [(ngModel)]="selectedResourceGroupInternal">
    <ng-template let-resourceGroup pTemplate="item">
      <div class="selection-list-entries" (click)="selectResourceGroup(resourceGroup)">
        <div>{{ resourceGroup.name }}</div>
        <i class="pi pi-chevron-right select-icon"></i>
      </div>
    </ng-template>
  </p-listbox>
</div>
