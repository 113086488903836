<p-selectButton
  [options]="getStateOptions()"
  [(ngModel)]="selectedState"
  optionLabel="label"
  optionValue="value"
  unselectable="true"
  optionDisabled="constant"
/>
<ng-container [ngSwitch]="selectedState">
  <!--Tenant selection-->
  <ng-container *ngSwitchCase="availableButtons.TENANT_BUTTON">
    <app-tenant-selection-for-resources (selectedTenant)="onTenantSelect($event)"></app-tenant-selection-for-resources>
  </ng-container>
  <!--Subscription selection-->
  <ng-container *ngSwitchCase="availableButtons.SUBSCRIPTION_BUTTON">
    <app-subscription-selection (selectedSubscription)="onSubscriptionSelect($event)"></app-subscription-selection>
  </ng-container>
  <!--Resource group selection-->
  <ng-container *ngSwitchCase="availableButtons.RESOURCE_GROUP_BUTTON">
    <app-resource-group-selection
      [selectedSubscription$]="selectedSubscription$"
      (selectedResourceGroup)="onResourceGroupSelect($event)"
    ></app-resource-group-selection>
  </ng-container>
  <!--Resource selection-->
  <ng-container *ngSwitchCase="availableButtons.RESOURCE_BUTTON">
    <app-resource-selection
      [selectedSubscription$]="selectedSubscription$"
      [selectedResourceGroup$]="selectedResourceGroup$"
      [selectedResourceType]="addResourceModel.selectedResourceType"
      [existingResources]="addResourceModel.existingResources"
      (selectedResource)="onResourceSelect($event)"
    ></app-resource-selection>
  </ng-container>
  <!--Confirm selection-->
  <ng-container *ngSwitchCase="availableButtons.CONFIRM_BUTTON">
    <app-confirm-selection
      [selectedTenant$]="selectedTenant$"
      [selectedSubscription$]="selectedSubscription$"
      [selectedResourceGroup$]="selectedResourceGroup$"
      [selectedResource$]="selectedResource$"
    ></app-confirm-selection>

    <button
      pButton
      [label]="this.dataService.getTexts().AddResourceConfirmationAddResourceButtonText"
      icon="pi pi-plus"
      (click)="addResource()"
      class="add-resource-button"
    ></button>
  </ng-container>
</ng-container>
