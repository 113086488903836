import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-progressspinner {
    position: relative;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    display: inline-block;
}

.p-progressspinner::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.p-progressspinner-spin {
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: p-progressspinner-rotate 2s linear infinite;
}

.p-progressspinner-circle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: ${dt('progressspinner.color.1')};
    animation: p-progressspinner-dash 1.5s ease-in-out infinite, p-progressspinner-color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes p-progressspinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes p-progressspinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@keyframes p-progressspinner-color {
    100%,
    0% {
        stroke: ${dt('progressspinner.color.1')};
    }
    40% {
        stroke: ${dt('progressspinner.color.2')};
    }
    66% {
        stroke: ${dt('progressspinner.color.3')};
    }
    80%,
    90% {
        stroke: ${dt('progressspinner.color.4')};
    }
}
`;
const classes = {
  root: 'p-progressspinner',
  spin: 'p-progressspinner-spin',
  circle: 'p-progressspinner-circle'
};
class ProgressSpinnerStyle extends BaseStyle {
  name = 'progressspinner';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵProgressSpinnerStyle_BaseFactory;
    return function ProgressSpinnerStyle_Factory(__ngFactoryType__) {
      return (ɵProgressSpinnerStyle_BaseFactory || (ɵProgressSpinnerStyle_BaseFactory = i0.ɵɵgetInheritedFactory(ProgressSpinnerStyle)))(__ngFactoryType__ || ProgressSpinnerStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: ProgressSpinnerStyle,
    factory: ProgressSpinnerStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressSpinnerStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * ProgressSpinner is a process status indicator.
 *
 * [Live Demo](https://www.primeng.org/progressspinner)
 *
 * @module progressspinnerstyle
 *
 */
var ProgressSpinnerClasses;
(function (ProgressSpinnerClasses) {
  /**
   * Class name of the root element
   */
  ProgressSpinnerClasses["root"] = "p-progressspinner";
  /**
   * Class name of the spin element
   */
  ProgressSpinnerClasses["spin"] = "p-progressspinner-spin";
  /**
   * Class name of the circle element
   */
  ProgressSpinnerClasses["circle"] = "p-progressspinner-circle";
})(ProgressSpinnerClasses || (ProgressSpinnerClasses = {}));

/**
 * ProgressSpinner is a process status indicator.
 * @group Components
 */
class ProgressSpinner extends BaseComponent {
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Width of the circle stroke.
   * @group Props
   */
  strokeWidth = '2';
  /**
   * Color for the background of the circle.
   * @group Props
   */
  fill = 'none';
  /**
   * Duration of the rotate animation.
   * @group Props
   */
  animationDuration = '2s';
  /**
   * Used to define a aria label attribute the current element.
   * @group Props
   */
  ariaLabel;
  _componentStyle = inject(ProgressSpinnerStyle);
  static ɵfac = /*@__PURE__*/(() => {
    let ɵProgressSpinner_BaseFactory;
    return function ProgressSpinner_Factory(__ngFactoryType__) {
      return (ɵProgressSpinner_BaseFactory || (ɵProgressSpinner_BaseFactory = i0.ɵɵgetInheritedFactory(ProgressSpinner)))(__ngFactoryType__ || ProgressSpinner);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: ProgressSpinner,
    selectors: [["p-progressSpinner"], ["p-progress-spinner"], ["p-progressspinner"]],
    inputs: {
      styleClass: "styleClass",
      style: "style",
      strokeWidth: "strokeWidth",
      fill: "fill",
      animationDuration: "animationDuration",
      ariaLabel: "ariaLabel"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([ProgressSpinnerStyle]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 3,
    vars: 11,
    consts: [["role", "progressbar", 1, "p-progressspinner", 3, "ngStyle", "ngClass"], ["viewBox", "25 25 50 50", 1, "p-progressspinner-spin"], ["cx", "50", "cy", "50", "r", "20", "stroke-miterlimit", "10", 1, "p-progressspinner-circle"]],
    template: function ProgressSpinner_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(1, "svg", 1);
        i0.ɵɵelement(2, "circle", 2);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngStyle", ctx.style)("ngClass", ctx.styleClass);
        i0.ɵɵattribute("aria-label", ctx.ariaLabel)("aria-busy", true)("data-pc-name", "progressspinner")("data-pc-section", "root");
        i0.ɵɵadvance();
        i0.ɵɵstyleProp("animation-duration", ctx.animationDuration);
        i0.ɵɵattribute("data-pc-section", "root");
        i0.ɵɵadvance();
        i0.ɵɵattribute("fill", ctx.fill)("stroke-width", ctx.strokeWidth);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgStyle, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressSpinner, [{
    type: Component,
    args: [{
      selector: 'p-progressSpinner, p-progress-spinner, p-progressspinner',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: `
        <div class="p-progressspinner" [ngStyle]="style" [ngClass]="styleClass" role="progressbar" [attr.aria-label]="ariaLabel" [attr.aria-busy]="true" [attr.data-pc-name]="'progressspinner'" [attr.data-pc-section]="'root'">
            <svg class="p-progressspinner-spin" viewBox="25 25 50 50" [style.animation-duration]="animationDuration" [attr.data-pc-section]="'root'">
                <circle class="p-progressspinner-circle" cx="50" cy="50" r="20" [attr.fill]="fill" [attr.stroke-width]="strokeWidth" stroke-miterlimit="10" />
            </svg>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [ProgressSpinnerStyle]
    }]
  }], null, {
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    strokeWidth: [{
      type: Input
    }],
    fill: [{
      type: Input
    }],
    animationDuration: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(ProgressSpinner, {
    className: "ProgressSpinner"
  });
})();
class ProgressSpinnerModule {
  static ɵfac = function ProgressSpinnerModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ProgressSpinnerModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: ProgressSpinnerModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [ProgressSpinner, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressSpinnerModule, [{
    type: NgModule,
    args: [{
      imports: [ProgressSpinner, SharedModule],
      exports: [ProgressSpinner, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ProgressSpinnerModule, {
    imports: [ProgressSpinner, SharedModule],
    exports: [ProgressSpinner, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ProgressSpinner, ProgressSpinnerClasses, ProgressSpinnerModule, ProgressSpinnerStyle };
