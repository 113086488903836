import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { GetTenantDTO } from '../../models/getTenantDTO';
import { ResourceDto } from 'app/core/api/models/resourceDTO.model';
import { Tenant } from '../../models/tenant';
import { AddRessources } from '../../services/AddRessource.service';
import { ApiService } from '../../services/api-service/api.service';
import { DataService } from '@shared/services/data/data.service';
import { TenantSelectionForResourcesComponent } from './components/tenant-selection-for-resources/tenant-selection-for-resources.component';
import { SubscriptionSelectionComponent } from './components/subscription-selection/subscription-selection.component';
import { AzureSubscriptionDto } from '../../services/dtos/azure-subscriptions.dto';
import { ResourceGroupSelectionComponent } from './components/resource-group-selection/resource-group-selection.component';
import { AzureResourceGroupDto } from '../../services/dtos/azure-resource-groups.dto';
import { ResourceSelectionComponent } from './components/resource-selection/resource-selection.component';
import { AzureResourceDto } from '../../services/dtos/azure-resources.dto';
import { ConfirmSelectionComponent } from './components/confirm-selection/confirm-selection.component';
import { AddResourceComponentModel, AddResourceModel } from './models/add-resource.model';
import { SelectableButtonOptions } from './constants/selectable-button-options.constant';
import { AvailableButtons, ButtonSelectionModel } from './models/button-selection.model';

@Component({
  selector: 'app-add-resource',
  templateUrl: './add-resource.component.html',
  styleUrls: ['./add-resource.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    SelectButtonModule,
    TenantSelectionForResourcesComponent,
    SubscriptionSelectionComponent,
    ResourceGroupSelectionComponent,
    ResourceSelectionComponent,
    ConfirmSelectionComponent,
  ],
})
export class AddResourceComponent {
  selectedTenant$: BehaviorSubject<Tenant> = new BehaviorSubject<Tenant>(null);
  selectedSubscription$: BehaviorSubject<AzureSubscriptionDto> = new BehaviorSubject<AzureSubscriptionDto>(null);
  selectedResourceGroup$: BehaviorSubject<AzureResourceGroupDto> = new BehaviorSubject<AzureResourceGroupDto>(null);
  selectedResource$: BehaviorSubject<AzureResourceDto> = new BehaviorSubject<AzureResourceDto>(null);

  tenant: Tenant;
  subscription: AzureSubscriptionDto;
  resourceGroup: AzureResourceGroupDto;
  resource: AzureResourceDto;

  addResourceModel: AddResourceComponentModel;

  availableButtons = AvailableButtons;
  numberOfAvailableButtons = Object.keys(AvailableButtons).length;
  stateOptions: Record<AvailableButtons, ButtonSelectionModel> = SelectableButtonOptions;
  selectedState: AvailableButtons = 0;

  constructor(
    private addResourceService: AddRessources,
    private apidata: ApiService,
    public dataService: DataService,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    this.addResourceModel = this.dialogConfig.data as AddResourceComponentModel;

    // Load the translations for the buttons
    this.stateOptions[AvailableButtons.TENANT_BUTTON].label =
      this.dataService.getTexts().AddResourceSelectTenantStepTitle;
    this.stateOptions[AvailableButtons.SUBSCRIPTION_BUTTON].label =
      this.dataService.getTexts().AddResourceSelectSubscriptionStepTitle;
    this.stateOptions[AvailableButtons.RESOURCE_GROUP_BUTTON].label =
      this.dataService.getTexts().AddResourceSelectResourceGroupStepTitle;
    this.stateOptions[AvailableButtons.RESOURCE_BUTTON].label =
      this.dataService.getTexts().AddResourceSelectResourceStepTitle;
    this.stateOptions[AvailableButtons.CONFIRM_BUTTON].label =
      this.dataService.getTexts().AddResourceSelectConfirmationStepTitle;
  }

  getStateOptions(): any[] {
    return Object.values(this.stateOptions);
  }

  onTenantSelect(tenant: Tenant): void {
    this.tenant = tenant;
    this.selectedTenant$.next(tenant);
    this.selectNextButton();
    this.resetFollowingButtons();
  }

  onSubscriptionSelect(subscription: AzureSubscriptionDto): void {
    this.subscription = subscription;
    this.selectedSubscription$.next(subscription);
    this.selectNextButton();
    this.resetFollowingButtons();
  }

  onResourceGroupSelect(resourceGroup: AzureResourceGroupDto): void {
    this.resourceGroup = resourceGroup;
    this.selectedResourceGroup$.next(resourceGroup);
    this.selectNextButton();
    this.resetFollowingButtons();
  }

  onResourceSelect(resource: AzureResourceDto): void {
    this.resource = resource;
    this.selectedResource$.next(resource);
    this.selectNextButton();
    this.resetFollowingButtons();
  }

  close(): void {
    this.dialogRef.close();
  }

  addResource(): void {
    this.dialogRef.close({
      selectedTenant: this.tenant,
      selectedSubscription: this.subscription,
      selectedResourceGroup: this.resourceGroup,
      selectedResource: this.resource,
    } as AddResourceModel);
  }

  /**
   * Selects the next button and enables it to be interactable by the user.
   */
  private selectNextButton(): void {
    this.selectedState++;
    this.stateOptions[this.selectedState].constant = false;
  }

  /**
   * Disables user interaction for all buttons after the currently selected one.
   */
  private resetFollowingButtons(): void {
    for (let i = this.selectedState + 1; i < this.numberOfAvailableButtons; i++) {
      this.stateOptions[i].constant = true;
    }
  }
}
