import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { SCOPES } from '../authorization/constants/scopes.constant';
import { MsalService } from '@azure/msal-angular';
import { SESSION_STORAGE_KEYS } from '../session_storage/constants/session-storage-keys.constant';

export let tenantId = localStorage.getItem(SESSION_STORAGE_KEYS.TENANT_ID) ?? 'organizations';

export let authConfig = {
  auth: {
    clientId: environment.clientId,
    authority: `https://login.microsoftonline.com/${tenantId}/`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  // fürs Bessere loggin in der Konsole einschalten
  //console.log(message);
}

export function setMsalTenantId(newTenantId: string) {
  tenantId = newTenantId;
}

export function getMsalTenantId() {
  return tenantId;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(authConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [SCOPES.USER_IMPERSONATION],
    },
    loginFailedRoute: './login-failed',
  };
}

export function getAllAccounts(): any {
  return this.authService.instance.getAllAccounts();
}

export function MSALInterceptorConfigFactory(msalService: MsalService): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [environment.baseUrl + 'tenants/connected', ['https://management.azure.com/user_impersonation']],
      [environment.baseUrl + 'onboarding/confirm', ['https://management.azure.com/user_impersonation']],
      [environment.baseUrl + 'onboarding/finish', ['https://management.azure.com/user_impersonation']],
      [environment.baseUrl + 'onboarding/all', ['api://c08e2c98-a937-4642-837e-2105b3296830/api.scope']],
      [environment.baseUrl + 'onboarding/new', ['api://c08e2c98-a937-4642-837e-2105b3296830/api.scope']],
      [environment.baseUrl + 'management', ['api://c08e2c98-a937-4642-837e-2105b3296830/api.scope']],
      [environment.baseUrl + 'offboarding', ['api://c08e2c98-a937-4642-837e-2105b3296830/api.scope']],
      ['https://management.azure.com/', ['https://management.azure.com/user_impersonation']],
      ['https://graph.microsoft.com/v1.0/applications', ['https://graph.microsoft.com/application.readWrite.all']],
      ['https://graph.microsoft.com/', ['https://graph.microsoft.com/user.read']],
    ]),

    authRequest: (msalService, httpReq, originalAuthRequest) => {
      if (!originalAuthRequest.account) {
        getAllAccounts();
        msalService.loginRedirect({
          scopes: [SCOPES.USER_IMPERSONATION],
        });
      }

      return {
        ...originalAuthRequest,
        authority: `https://login.microsoftonline.com/${originalAuthRequest.account?.tenantId ?? 'organizations'}`,
      };
    },
  };
}
