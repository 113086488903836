<div class="confirm-selection-container">
  <p-table [value]="currentSelectionTexts">
    <ng-template pTemplate="body" let-selection>
      <tr>
        <td>{{ selection.infoText }}:</td>
        <td>{{ selection.itemName }}</td>
        <td><i class="pi pi-check verify-icon-ok"></i></td>
      </tr>
    </ng-template>
  </p-table>
</div>
