<div class="subscription-selection-container">
  <p-listbox [options]="azureSubscriptions$ | async" [(ngModel)]="selectedSubscriptionInternal">
    <ng-template let-subscription pTemplate="item">
      <div class="selection-list-entries" (click)="selectAzureSubscription(subscription)">
        <div>{{ subscription.displayName }}</div>
        <i class="pi pi-chevron-right select-icon"></i>
      </div>
    </ng-template>
  </p-listbox>
</div>
