<div class="delete-pop-up-container">
  <div *ngFor="let entry of data" class="entries-to-delete">
    <p-avatar shape="circle">{{ getFirstLetterFromName(entry.name) }}</p-avatar>
    <div>
      <p class="no-margin-bold">{{ entry.name }}</p>
      <p class="no-margin-gray">{{ entry.info }}</p>
    </div>
  </div>

  <div *ngIf="data.length > 0">
    <p-divider></p-divider>
  </div>

  <div class="delete-pop-up-buttons">
    <p-button
      (click)="onNoClick()"
      [text]="true"
      icon="pi pi-times"
      label="{{ this.dataService.getTexts().ButtonTextCancel }}"
    />
    <p-button (click)="delete()" icon="pi pi-trash" label="{{ dataService.getTexts().ButtonTextDelete }}" />
  </div>
</div>
