import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { DeletePopUpInfo } from './models/delete-pop-up-info';
import { DataService } from '@shared/services/data/data.service';

@Component({
  selector: 'app-delete-pop-up',
  templateUrl: './delete-pop-up.component.html',
  styleUrls: ['./delete-pop-up.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonModule, AvatarModule],
})
export class DeletePopUpComponent {
  data: Array<DeletePopUpInfo>;

  constructor(
    public dataService: DataService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
  ) {
    this.data = this.dialogConfig.data as Array<DeletePopUpInfo>;
  }

  getFirstLetterFromName(name: string): string {
    return name.charAt(0).toUpperCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete(): void {
    this.dialogRef.close(true);
  }
}
