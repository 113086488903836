import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { ListboxModule } from 'primeng/listbox';
import { DataService } from '@shared/services/data/data.service';
import { AddRessources } from 'app/features/admin/services/AddRessource.service';
import { AzureSubscriptionDto } from 'app/features/admin/services/dtos/azure-subscriptions.dto';
import { AzureResourceGroupDto } from 'app/features/admin/services/dtos/azure-resource-groups.dto';

@Component({
  selector: 'app-resource-group-selection',
  templateUrl: './resource-group-selection.component.html',
  styleUrl: './resource-group-selection.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, ListboxModule],
})
export class ResourceGroupSelectionComponent implements OnInit {
  @Input({ required: true }) selectedSubscription$: Observable<AzureSubscriptionDto>;

  @Output() selectedResourceGroup = new EventEmitter<AzureResourceGroupDto>();

  azureResourceGroups$: Observable<AzureResourceGroupDto[]>;
  selectedResourceGroupInternal: AzureResourceGroupDto;

  constructor(
    private addRessourcesService: AddRessources,
    public dataService: DataService,
  ) {}

  ngOnInit(): void {
    this.selectedSubscription$.subscribe((subscription: AzureSubscriptionDto) => {
      this.azureResourceGroups$ = this.addRessourcesService.getUserResourceGroups(subscription.subscriptionId);
    });
  }

  selectResourceGroup(resourceGroup: AzureResourceGroupDto): void {
    this.selectedResourceGroup.emit(resourceGroup);
  }
}
