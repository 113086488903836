import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { ListboxModule } from 'primeng/listbox';
import { DataService } from '@shared/services/data/data.service';
import { Tenant } from 'app/features/admin/models/tenant';
import { TenantService } from 'app/features/admin/services/tenant.service';

@Component({
  selector: 'app-tenant-selection-for-resources',
  templateUrl: './tenant-selection-for-resources.component.html',
  styleUrl: './tenant-selection-for-resources.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, ListboxModule],
})
export class TenantSelectionForResourcesComponent implements OnInit {
  @Output() selectedTenant = new EventEmitter<Tenant>();

  tenants$: Observable<Tenant[]>;
  selectedTenantInternal: Tenant;

  constructor(
    private tenantService: TenantService,
    public dataService: DataService,
  ) {}

  ngOnInit(): void {
    this.tenants$ = this.tenantService.getAdminTenantsList();
  }

  selectTenant(tenant: Tenant): void {
    this.selectedTenant.emit(tenant);
  }
}
