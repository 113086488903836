import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ListboxModule } from 'primeng/listbox';
import { Observable } from 'rxjs';
import { DataService } from '@shared/services/data/data.service';
import { AddRessources } from 'app/features/admin/services/AddRessource.service';
import { AzureSubscriptionDto } from 'app/features/admin/services/dtos/azure-subscriptions.dto';

@Component({
  selector: 'app-subscription-selection',
  templateUrl: './subscription-selection.component.html',
  styleUrl: './subscription-selection.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, ListboxModule],
})
export class SubscriptionSelectionComponent implements OnInit {
  @Output() selectedSubscription = new EventEmitter<AzureSubscriptionDto>();

  azureSubscriptions$: Observable<AzureSubscriptionDto[]>;
  selectedSubscriptionInternal: AzureSubscriptionDto;

  constructor(
    private addRessourcesService: AddRessources,
    public dataService: DataService,
  ) {}

  ngOnInit(): void {
    this.azureSubscriptions$ = this.addRessourcesService.getUserSubscriptions();
  }

  selectAzureSubscription(subscription: AzureSubscriptionDto): void {
    this.selectedSubscription.emit(subscription);
  }
}
