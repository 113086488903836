import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { forkJoin, map, Observable, take } from 'rxjs';
import { ListboxModule } from 'primeng/listbox';
import { DataService } from '@shared/services/data/data.service';
import { AddRessources } from 'app/features/admin/services/AddRessource.service';
import { AzureResourceGroupDto } from 'app/features/admin/services/dtos/azure-resource-groups.dto';
import { AzureSubscriptionDto } from 'app/features/admin/services/dtos/azure-subscriptions.dto';
import { AzureResourceDto } from 'app/features/admin/services/dtos/azure-resources.dto';
import { SupportedResources } from '../../constants/supported-resources.constant';
import { ResourceDto } from 'app/core/api/models/resourceDTO.model';

@Component({
  selector: 'app-resource-selection',
  templateUrl: './resource-selection.component.html',
  styleUrl: './resource-selection.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, ListboxModule],
})
export class ResourceSelectionComponent implements OnInit {
  @Input({ required: true }) selectedSubscription$: Observable<AzureSubscriptionDto>;
  @Input({ required: true }) selectedResourceGroup$: Observable<AzureResourceGroupDto>;
  @Input() existingResources: ResourceDto[];
  @Input() selectedResourceType: SupportedResources[];

  @Output() selectedResource = new EventEmitter<AzureResourceDto>();

  azureResources$: Observable<AzureResourceDto[]>;
  selectedResourceInternal: AzureResourceDto;

  constructor(
    private addRessourcesService: AddRessources,
    public dataService: DataService,
  ) {}

  ngOnInit(): void {
    forkJoin([this.selectedSubscription$.pipe(take(1)), this.selectedResourceGroup$.pipe(take(1))]).subscribe(
      ([subscription, resourceGroup]) => {
        this.azureResources$ = this.addRessourcesService
          .getUserResources(subscription.subscriptionId, resourceGroup.name)
          .pipe(map((resources) => this.filterResources(resources)));
      },
    );
  }

  selectResource(resource: AzureResourceDto): void {
    this.selectedResource.emit(resource);
  }

  private filterResources(resources: AzureResourceDto[]): AzureResourceDto[] {
    // Filter out resources that are not supported
    let filteredResources: AzureResourceDto[] = resources.filter((resource: AzureResourceDto) =>
      this.selectedResourceType.includes(resource.type as SupportedResources),
    );

    // Filter out resources that had already been added to the department
    if (this.existingResources?.length > 0) {
      filteredResources = filteredResources.filter(
        (resource: AzureResourceDto) =>
          !this.existingResources.some((existingResource) => existingResource.name === resource.name),
      );
    }

    return filteredResources;
  }
}
