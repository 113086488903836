export enum AvailableButtons {
  TENANT_BUTTON = 0,
  SUBSCRIPTION_BUTTON = 1,
  RESOURCE_GROUP_BUTTON = 2,
  RESOURCE_BUTTON = 3,
  CONFIRM_BUTTON = 4,
}

export interface ButtonSelectionModel {
  label: string;
  value: AvailableButtons;
  constant: boolean;
}
