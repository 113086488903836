import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, Observable, retry, shareReplay, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { Department } from 'app/features/admin/models/department'; // Move to Shared / Core ?
import { CurrentUserDataDTO } from '@shared/models/currentUserDataDTO';
import { DepartmentDTO } from '@shared/models/departmentDTO';
import { UserDTO } from '@shared/models/userDTO';
import { VmPowerStatusDTO } from '@shared/models/vmPowerStatusDTO';
import { Group } from '@shared/models/group';
import { ResetPasswordDTO } from '@shared/models/resetPasswordRequestDTO';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private baseUrl = environment.baseUrl;

  private departmentController = 'management/departments';

  private resourceController = 'management/resources';

  private userController = 'management/users';

  private adminController = 'management/admins';

  private departmentList$: Observable<Department[]>;

  constructor(private http: HttpClient) {}

  getDepartment(): Observable<Department[]> {
    if (!this.departmentList$) {
      this.departmentList$ = this.http
        .get<Department[]>(this.baseUrl + this.departmentController + '/user')
        .pipe(retry(3), catchError(this.formatErrors))
    }
    return this.departmentList$;
  }

  getVmPowerStatusUpdate(resourceIds: string[]): Observable<VmPowerStatusDTO[]> {
    return this.http.put<VmPowerStatusDTO[]>(
      this.baseUrl + this.resourceController + '/vms/powerstatus/update',
      resourceIds,
    );
  }

  startVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentController + '/' + departmentId + '/resources/' + resourceId + '/vms/start',
      null,
    );
  }

  restartVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentController + '/' + departmentId + '/resources/' + resourceId + '/vms/restart',
      null,
    );
  }

  stopVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentController + '/' + departmentId + '/resources/' + resourceId + '/vms/stop',
      null,
    );
  }

  getDepartmentById(departmentId: string): Observable<DepartmentDTO> {
    return this.http
      .get<DepartmentDTO>(this.baseUrl + this.departmentController + '/' + departmentId)
      .pipe(retry(1), catchError(this.formatErrors))
      .pipe(shareReplay(1));
  }

  async getUserByDepartmentId(departmentId: string): Promise<UserDTO[]> {
    const response = this.http
      .get<UserDTO[]>(this.baseUrl + this.userController + '/departments/' + departmentId)
      .pipe(retry(1), catchError(this.formatErrors))
      .pipe(shareReplay(1));
    return firstValueFrom(response);
  }

  async getAdminsByOrganizationId(organizationId: string): Promise<UserDTO[]> {
    const response = this.http
      .get<UserDTO[]>(this.baseUrl + this.adminController + '/organizations/' + organizationId)
      .pipe(retry(1), catchError(this.formatErrors))
      .pipe(shareReplay(1));
    return firstValueFrom(response);
  }

  getCurrentUserData(): Observable<CurrentUserDataDTO> {
    return this.http.get<CurrentUserDataDTO>(this.baseUrl + this.userController + '/current');
  }

  private formatErrors(error: any): Observable<never> {
    return throwError(() => new Error(error.error));
  }

  getGroupsOfUser(token: string): Observable<Group[]> {
    let body = {
      token: token,
    };
    return this.http.post<Group[]>(this.baseUrl + 'management/groups', body);
  }

  getDetailedGroupInformation(groupId: string, token: string): Observable<Group> {
    let body = {
      token: token,
    };
    return this.http.post<Group>(this.baseUrl + 'management/groups/' + groupId, body);
  }

  resetPasswordForUser(userId: string, resetPasswordRequest: ResetPasswordDTO): Observable<string> {
    let body = {
      ...resetPasswordRequest,
    };
    return this.http.post<string>(this.baseUrl + this.userController + `/{userId}/password/reset`, body);
  }

  updateUser(user: UserDTO): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.baseUrl + this.userController, user);
  }
}
