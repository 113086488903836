import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserApiService } from 'app/features/user/services/api-service/api.service.component';
import { DataService } from '../../services/data/data.service';
import { AuthService } from '@shared/services/auth.service';
import { ThemeService } from '@shared/services/theme/theme.service';
import { SESSION_STORAGE_KEYS } from 'app/core/session_storage/constants/session-storage-keys.constant';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.scss'],
})
export class UsermenuComponent implements OnInit {
  avatar: boolean;
  pic: any;
  username = 'Guest';
  email = 'unknown';
  isDarkMode: boolean = true;
  isLanguageSwitchEnabled: boolean = false;
  isLanguageSelectionChecked: boolean = false;

  menuItems: MenuItem[] = [];

  constructor(
    private auth: AuthService,
    private apiService: UserApiService,
    private sanitizer: DomSanitizer,
    public dataService: DataService,
    public themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.isDarkMode = !this.themeService.isDarkMode();
    this.auth.getUser();
    this.getAadProfilePicture();
    this.loadUserData();
    this.setupMenuItems();

    this.dataService.getIsLanguageSwitchEnabled().subscribe((isLanguageSwitchEnabled: boolean) => {
      this.isLanguageSwitchEnabled = isLanguageSwitchEnabled;
      this.isLanguageSelectionChecked = this.dataService.getLanguage() === 'en';
      this.setupMenuItems();
    });
  }

  setupMenuItems(): void {
    this.menuItems = [
      {
        label: `${this.username} (${this.email})`,
        disabled: true,
        style: { textAlign: 'center', fontWeight: 'bold' },
      },
      { separator: true },
      {
        label: 'Language',
        items: [
          {
            label: 'DE',
            icon: !this.isLanguageSelectionChecked ? 'pi pi-check' : '',
            command: () => this.setLanguage('de'),
          },
          {
            label: 'EN',
            icon: this.isLanguageSelectionChecked ? 'pi pi-check' : '',
            command: () => this.setLanguage('en'),
          },
        ],
      },
      {
        label: 'Theme',
        items: [
          {
            label: 'Light Mode',
            icon: this.isDarkMode ? 'pi pi-check' : '',
            command: () => this.toggleDarkMode(),
          },
          {
            label: 'Dark Mode',
            icon: !this.isDarkMode ? 'pi pi-check' : '',
            command: () => this.toggleDarkMode(),
          },
        ],
      },
      { separator: true },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => this.signOut(),
      },
    ];
  }

  loadUserData(): void {
    this.apiService.getCurrentUserData().subscribe((data) => {
      this.username = data.name;
      this.email = data.email;
      this.setupMenuItems();
    });
  }

  getAadProfilePicture(): void {
    this.auth.getAadProfilePicture().subscribe({
      next: (image: any) => {
        if (image != null) {
          const objectURL = URL.createObjectURL(image);
          this.pic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.avatar = true;
        } else {
          this.avatar = false;
        }
      },
      error: () => {
        this.avatar = false;
      },
    });
  }

  toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
    this.themeService.toggleDarkMode();
    this.setupMenuItems();
  }

  setLanguage(lang: string): void {
    localStorage.setItem(SESSION_STORAGE_KEYS.CLIENT_LANGUAGE, lang);
    this.dataService.setLanguage(lang);
    this.isLanguageSelectionChecked = lang === 'en';
    this.setupMenuItems();
  }

  signOut(): void {
    localStorage.removeItem(SESSION_STORAGE_KEYS.TENANT_ID);
    this.auth.logout();
  }

  getAvatarUrl(): string {
    return `https://ui-avatars.com/api/?name=${this.username}`;
  }
}
