import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenResponse } from '@shared/models/tokenResponse';
import { SCOPES } from 'app/core/authorization/constants/scopes.constant';
import { AuthorizationService } from 'app/core/authorization/services/authorization.service';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { AzureSubscriptionDto, AzureSubscriptionsDto } from './dtos/azure-subscriptions.dto';
import { AzureResourceGroupDto, AzureResourceGroupsDto } from './dtos/azure-resource-groups.dto';
import { AzureResourcesDto, AzureResourceDto } from './dtos/azure-resources.dto';

@Injectable({
  providedIn: 'root',
})
export class AddRessources {
  constructor(
    private http: HttpClient,
    private userAuthorizationService: AuthorizationService,
  ) {}

  getUserTenants(): Observable<any> {
    return this.http.get<any>('https://management.azure.com/tenants?api-version=2022-12-01').pipe(retry(3));
  }

  getUserSubscriptions(): Observable<AzureSubscriptionDto[]> {
    return this.http
      .get<AzureSubscriptionsDto>('https://management.azure.com/subscriptions?api-version=2020-01-01')
      .pipe(
        retry(3),
        map((response) => response.value as AzureSubscriptionDto[]),
      );
  }

  getUserResourceGroups(subscriptionId: string): Observable<AzureResourceGroupDto[]> {
    return this.http
      .get<AzureResourceGroupsDto>(
        `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups?api-version=2020-06-01`,
      )
      .pipe(
        retry(3),
        map((response) => response.value as AzureResourceGroupDto[]),
      );
  }

  getUserResources(subscriptionId: string, resourceGroup: string): Observable<AzureResourceDto[]> {
    return this.http
      .get<AzureResourcesDto>(
        `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/resources?api-version=2020-06-01`,
      )
      .pipe(
        retry(3),
        map((response) => response.value as AzureResourceDto[]),
      );
  }

  addServicePrincipalToResource(
    subscriptionId: string,
    subscriptionScope: string,
    uuid: string,
    clientId: string,
    tenantId: string,
  ): Observable<any> {
    this.userAuthorizationService
      .acquireToken([
        SCOPES.APPLICATION_READ_WRITE_ALL,
        SCOPES.APPLICATION_READ_ALL,
        SCOPES.DIRECTORY_READ_ALL,
        SCOPES.DIRECTORY_READ_WRITE_ALL,
      ])
      .subscribe({
        next: (value: any) => {
          const tokenResponse: TokenResponse = value as TokenResponse;
          this.http
            .get<any>(`https://graph.microsoft.com/v1.0/servicePrincipals?$filter=appId eq '${clientId}'`, {
              headers: { Authorization: `Bearer ${tokenResponse.accessToken}` },
            })
            .subscribe({
              next: (result: any) => {
                return this.http.put<any>(
                  `https://management.azure.com${subscriptionScope}/providers/Microsoft.Authorization/roleAssignments/${uuid}?api-version=2022-04-01`,
                  {
                    properties: {
                      roleDefinitionId: `/subscriptions/${subscriptionId}/providers/Microsoft.Authorization/roleDefinitions/b24988ac-6180-42a0-ab88-20f7382dd24c`,
                      principalId: result.value[0].id,
                    },
                  },
                );
              },
              error: (err: any) => {
                console.error(err);
              },
            });
        },
        error: (err: any) => {
          console.log(err);
        },
      });
    return new Observable();
  }
}
