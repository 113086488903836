import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import { definePreset, usePreset } from '@primeng/themes';
import { environment } from '@environments/environment';
import { AvailableThemes } from './types/available-themes.type';
import { AVAILABLE_THEMES } from './constants/available-themes.constant';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly DARK_MODE_STORAGE_KEY = 'is-dark-mode';
  private readonly LIGHT_THEME_STORAGE_KEY = 'light-theme';
  private readonly DARK_THEME_STORAGE_KEY = 'dark-theme';

  private defaultTheme = Aura.primitive[environment.defaultThemeName];
  private selectedLightTheme: any;
  private selectedDarkTheme: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private primeng: PrimeNG,
  ) {
    this.initializeThemes();
  }

  private initializeThemes(): void {
    const savedLightTheme = localStorage.getItem(this.LIGHT_THEME_STORAGE_KEY);
    const savedDarkTheme = localStorage.getItem(this.DARK_THEME_STORAGE_KEY);

    this.selectedLightTheme = this.validateAndGetTheme(savedLightTheme);
    this.selectedDarkTheme = this.validateAndGetTheme(savedDarkTheme);

    const themePreset = this.createThemePreset(this.selectedLightTheme, this.selectedDarkTheme);
    this.primeng.theme.set({
      preset: themePreset,
      options: {
        prefix: 'p',
        darkModeSelector: '.dark-mode',
        cssLayer: false,
      },
    });

    if (this.getDarkModeFromLocalStorage()) {
      this.applyDarkMode(true);
    }
  }

  public setLightTheme(newLightTheme: AvailableThemes): void {
    this.selectedLightTheme = Aura.primitive[newLightTheme];
    localStorage.setItem(this.LIGHT_THEME_STORAGE_KEY, newLightTheme);
    usePreset(this.createThemePreset(this.selectedLightTheme, this.selectedDarkTheme));
  }

  public setDarkTheme(newDarkTheme: AvailableThemes): void {
    this.selectedDarkTheme = Aura.primitive[newDarkTheme];
    localStorage.setItem(this.DARK_THEME_STORAGE_KEY, newDarkTheme);
    usePreset(this.createThemePreset(this.selectedLightTheme, this.selectedDarkTheme));
  }

  public toggleDarkMode(): void {
    const isDarkMode = this.isDarkMode();
    this.applyDarkMode(!isDarkMode);
    this.updateDarkModeInLocalStorage();
  }

  private applyDarkMode(enable: boolean): void {
    const element = document.querySelector('html');
    if (enable) {
      element.classList.add('dark-mode');
    } else {
      element.classList.remove('dark-mode');
    }
  }

  public isDarkMode(): boolean {
    return document.querySelector('html').classList.contains('dark-mode');
  }

  private createThemePreset(lightTheme: any, darkTheme: any): any {
    return definePreset(Aura, {
      semantic: {
        primary: lightTheme,
        colorScheme: {
          light: {
            primary: lightTheme,
          },
          dark: {
            primary: darkTheme,
          },
        },
      },
    });
  }

  private updateDarkModeInLocalStorage(): void {
    localStorage.setItem(this.DARK_MODE_STORAGE_KEY, this.isDarkMode() ? 'true' : 'false');
  }

  private getDarkModeFromLocalStorage(): boolean {
    return localStorage.getItem(this.DARK_MODE_STORAGE_KEY) === 'true';
  }

  private validateAndGetTheme(savedTheme: string | null): any {
    if (!savedTheme || !this.isValidTheme(savedTheme)) {
      return this.defaultTheme;
    }
    return Aura.primitive[savedTheme as AvailableThemes];
  }

  private isValidTheme(theme: string | null): theme is AvailableThemes {
    return theme !== null && Object.values(AVAILABLE_THEMES).includes(theme as AvailableThemes);
  }

  public getCurrentLightTheme(): AvailableThemes {
    const savedTheme = localStorage.getItem(this.LIGHT_THEME_STORAGE_KEY);
    return this.isValidTheme(savedTheme)
      ? (savedTheme as AvailableThemes)
      : (environment.defaultThemeName as AvailableThemes);
  }

  public getCurrentDarkTheme(): AvailableThemes {
    const savedTheme = localStorage.getItem(this.DARK_THEME_STORAGE_KEY);
    return this.isValidTheme(savedTheme)
      ? (savedTheme as AvailableThemes)
      : (environment.defaultThemeName as AvailableThemes);
  }
}
